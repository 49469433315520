<template>
  <div>
    <h1 class="text-size-h1 text-bold mb-s">Данные профиля</h1>

    <div class="panel radius-xl mb-m">
      <div class="text-size-h2 text-bold mb-l">
        {{ user.full_name }}
      </div>

      <div class="account__row">
        <div class="account__row-item mb-m">
          <label class="form-label text-size-xs">Пол</label>
          <div class="text-size-h4 text-bold">
            {{ user.sex === "m" ? "Мужской" : "Женский" }}
          </div>
        </div>
        <div class="account__row-item mb-m">
          <label class="form-label text-size-xs">E-mail:</label>
          <div class="text-size-h4 text-bold">
            {{ talentUser.email }}
          </div>
        </div>
        <div class="account__row-item mb-m">
          <label class="form-label text-size-xs">Адрес</label>
          <div class="text-size-h4 text-bold">{{ user.address }}</div>
        </div>
      </div>

      <div
        v-if="!isMentor && education"
        class="account__row">
        <div class="account__row-item mb-m">
          <label class="form-label text-size-xs">Образование</label>
          <div class="text-size-h4 text-bold">{{ education.title }}</div>
          <p
            v-if="education.address"
            class="account__edu-address">
            {{ education.address }}
          </p>

          <p v-if="isJunior">
            Если ваша школа указана неверно, обратитесь в
            <a
              :href="`mailto:${$store.state.supportEmail}`"
              class="link link--pseudo"
              >службу поддержки</a
            >.
          </p>
          <a
            v-else-if="!education.organization"
            href="#"
            class="link link--pseudo"
            @click.prevent="openEduModal"
            >Изменить образование</a
          >
        </div>
        <div
          v-if="grade"
          class="account__row-item mb-m">
          <label class="form-label text-size-xs">{{
            isStudent ? "Курс" : "Класс"
          }}</label>
          <div class="text-size-h4 text-bold">{{ grade }}</div>
        </div>
        <div
          v-if="grade"
          class="account__row-item mb-m">
          <div class="mt-xs text-size-xs">
            Изменить {{ isStudent ? "курс" : "класс" }} можно через
            <a
              :href="`mailto:${$store.state.supportEmail}`"
              class="link link--pseudo"
              >службу поддержки</a
            >
          </div>
        </div>
      </div>

      <div class="text-size-h3 text-bold mt-m mb-m">Фото профиля</div>
      <div class="mb-l">
        <BaseLoadingBox :pending="avatarPending">
          <div class="photo__row">
            <BaseAvatar
              :gender="talentUser.sex"
              :image="talentUser.avatar"
              class="photo__avatar" />

            <span
              v-if="talentUser.avatar"
              class="photo__avatar-remove"
              tabindex="0"
              @click="handleDelAvatar"
              @keydown.enter="handleDelAvatar">
              <BaseIcon
                glyph="close"
                width="20px" />
              Удалить
            </span>

            <form
              ref="avatar"
              class="photo__form"
              @submit.prevent="handleSaveAvatar">
              <BaseButton
                tag="label"
                for="document"
                theme="primary-border">
                загрузить изображение
              </BaseButton>

              <div v-show="false">
                <input
                  id="document"
                  ref="fileInput"
                  type="file"
                  accept="image/jpeg,image/png"
                  @change="handleFileChange" />
              </div>
            </form>
          </div>
          <div
            v-if="avatarErrors && avatarErrors.length"
            class="form-error">
            {{ avatarErrors[0] }}
          </div>
        </BaseLoadingBox>
      </div>

      <div class="text-size-h3 text-bold mb-m">Личные данные</div>

      <BaseLoadingBox
        :pending="pending"
        class="mb-m">
        <div class="row">
          <div class="col-md-6 col-xs-12">
            <label
              class="form-label text-size-s"
              for="about"
              >СНИЛС</label
            >
            <ValidationProvider
              v-slot="{ errors }"
              ref="snils"
              name="snils"
              :rules="{ required: true }">
              <BaseInput
                id="snils"
                v-model="snils"
                name="snils"
                type="text"
                placeholder="Введите СНИЛС"
                :format="{
                  numericOnly: true,
                  blocks: [3, 3, 3, 2],
                  delimiter: '-',
                }"
                :errors="errors"
                @blur="handleSaveSnils" />
            </ValidationProvider>
          </div>
        </div>

        <p class="mt-s text-size-s">
          Номер СНИЛС необходим, чтобы мы могли включить призёров и победителей
          НТО в ГИР и ФИС ГИА.
        </p>
      </BaseLoadingBox>

      <SetPhone
        v-if="false"
        class="mb-m"
        @submit="setPhone" />

      <BaseLoadingBox :pending="pending">
        <label
          class="form-label text-size-s"
          for="about"
          >О себе</label
        >
        <BaseInput
          id="about"
          v-model="about"
          name="about"
          type="textarea"
          placeholder="Информация о пользователе "
          @blur="handleBlurAbout" />
      </BaseLoadingBox>

      <div
        v-if="!isMentor && !isJunior"
        id="merchsize"
        class="merch-size-section mt-m">
        <label
          class="form-label text-size-s"
          for="about"
          >Размер футболки</label
        >
        <div class="row">
          <div class="col-md-4 col-sm-6 col-xs-6 mb-xs">
            <BaseSelect
              v-model="merchSize"
              placeholder="Укажите размер"
              :allow-empty="false"
              :loading="sizePending"
              :disabled="sizePending"
              :searchable="false"
              :options="sizesOptions"
              @select="handleSetSize" />
          </div>
        </div>
      </div>

      <div class="mt-m">
        <p class="text-size-m">
          Персональные данные можно изменить в
          <a
            target="_blank"
            rel="noopener noreferrer"
            :href="$store.state.talentURL + '/profile'"
            class="link"
            >личном кабинете Таланта</a
          >
        </p>
      </div>
    </div>

    <div
      v-if="!isJunior"
      class="panel radius-xl mb-m">
      <BindYandex />
    </div>

    <!-- <div class="panel radius-xl mb-m">
      <div class="text-size-h3 text-bold mb-s">
        Удаление аккаунта в&nbsp;ОНТИ
      </div>
      <div class="mb-m">
        <p>
          У&nbsp;вас есть возможность удалить аккаунт в&nbsp;Олимпиале НТИ,
          но&nbsp;весь прогресс будет утерян.
        </p>
      </div>

      <div class="btn-row">
        <BaseButton theme="primary-border">удалить аккаунт онти</BaseButton
        >&emsp;
        <BaseButton theme="primary-border"
          >удалить аккаунт онти + талант</BaseButton
        >
      </div>

      <div class="panel__insert mt-l">
        <p class="mb-m">На&nbsp;твою почту будет отправлено письмо.</p>
        <p class="mb-m">
          Для удаления аккаунта необходимо перейти по&nbsp;указанной
          в&nbsp;письме ссылку. После этого ваш аккаунт будет удален
          в&nbsp;течение двух недель.
        </p>
        <p>
          По&nbsp;всем возникшим вопросам обращайтесь на&nbsp;почту
          <a href="mailto:talent@kruzhok.org" class="link"
            >talent@kruzhok.org</a
          >
        </p>
      </div>
    </div> -->

    <!-- <div v-if="!isMentor" class="panel radius-xl">
      <div class="text-size-h3 text-bold mb-s">Достижения прошлых лет</div>

      <div class="mb-m">
        <p>
          Диплом победителей и&nbsp;призеров существует только в&nbsp;бумажно
          виде
        </p>
      </div>
    </div> -->
  </div>
</template>

<script>
import {
  MAX_AVATAR_SIZE,
  MERCH_SIZES,
  MODAL_DYNAMIC_DEFAULTS,
} from "@/constants";
import SetPhone from "@/components/SetPhone";
import BindYandex from "@/components/BindYandex";
import { mapGetters } from "vuex";
import { validate } from "vee-validate";
import { request } from "@/services/api";
import UpdateEducationModal from "@/components/user/modals/UpdateEducationModal";
export default {
  name: "Account",
  metaInfo() {
    return {
      title: "Данные учетной записи",
    };
  },
  components: {
    SetPhone,
    BindYandex,
  },
  data() {
    return {
      file: null,
      avatarErrors: [],
      avatarPending: false,
      about: "",
      pending: false,
      merchSize: "",
      sizePending: false,
      snils: "",
    };
  },
  computed: {
    ...mapGetters({
      user: "user/user",
      isMentor: "user/isMentor",
      isJunior: "participant/isJunior",
    }),
    talentUser() {
      return this.$store.state.user.talentUser;
    },
    isStudent() {
      return this.user.role === "student";
    },
    grade() {
      return this.$store.state.participant.grade;
    },
    sizesOptions() {
      return MERCH_SIZES;
    },
    education() {
      const list = this.$store.state.user.educations || [];
      let current = list.find(
        (n) => n.id === this.$store.state.participant.talent_education_id
      );
      return current || list[0];
    },
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      this.about = this.talentUser?.about;
      this.snils = this.talentUser?.snils;

      try {
        await this.$store.dispatch("user/getEducation");
      } catch (error) {
        console.log(error);
      }
      const userMerchSize = this.$store.state.participant.merch_size;
      if (userMerchSize) {
        this.merchSize = userMerchSize;
      }
    },
    handleDelAvatar() {
      this.updateAvatar(null);
    },
    handleFileChange(e) {
      this.avatarErrors = [];
      this.file = e.target.files;
      this.handleSaveAvatar();
    },
    async handleSaveAvatar() {
      const { file } = this;
      const avatar = file && file[0];
      if (avatar) {
        const { errors } = await validate(file[0], {
          image: true,
          size: MAX_AVATAR_SIZE,
        });

        this.avatarErrors = errors;
        if (errors && errors.length) return;
      }
      this.updateAvatar(avatar);
    },
    async updateAvatar(avatar) {
      this.avatarPending = true;
      const fd = new FormData();
      fd.append("avatar", avatar);
      try {
        let payload;
        if (avatar) {
          payload = new FormData();
          payload.append("avatar", avatar);
        } else {
          payload = { avatar: null };
        }
        await this.$store.dispatch("user/updateUserInfo", payload);
      } catch (error) {
        this.avatarErrors = error;
      }
      this.avatarPending = false;
    },
    async setPhone(phone, session_token) {
      try {
        await this.$store.dispatch("user/updateUserInfo", {
          phone,
          session_token,
        });
      } catch (error) {
        this.showErrorModal({
          title: "Ошибка",
          message: error.message,
          status: error.status,
          content: "Не удалось обновить телефон",
        });
        console.log(error);
      }
    },
    async handleBlurAbout() {
      const { about } = this;
      if (about === this.talentUser.about) return;

      this.pending = true;

      try {
        await this.$store.dispatch("user/updateUserInfo", {
          about,
        });
      } catch (error) {
        this.showErrorModal({
          title: "Ошибка",
          message: error.message,
          status: error.status,
          content: "Не удалось обновить данные профиля",
        });
        console.log(error);
      }
      this.pending = false;
    },
    async handleSetSize(size) {
      if (typeof size === "string") {
        this.sizePending = true;
        try {
          const { data } = await request({
            method: "PATCH",
            url: "/participant",
            data: {
              merch_size: size,
            },
          });
          this.$store.commit("participant/SET_STATE", {
            key: "merch_size",
            value: data.merch_size,
          });
        } catch (error) {
          this.showErrorModal({
            title: "Ошибка",
            message: error.message,
            status: error.status,
            content: "Не удалось сохранить размер футболки",
          });
        }
        this.sizePending = false;
      }
    },
    async handleSaveSnils() {
      const { valid } = await this.$refs.snils.validate();
      if (!valid) return;
      const snils = this.snils.replaceAll("-", "");
      try {
        await this.$store.dispatch("user/updateUserInfo", {
          snils,
        });
      } catch (error) {
        this.$refs.snils.setErrors([error.message]);
      }
    },
    openEduModal() {
      this.$modal.show(
        UpdateEducationModal,
        {},
        { ...MODAL_DYNAMIC_DEFAULTS, classes: ["v--modal", "no-overflow"] }
      );
    },
  },
};
</script>

<style lang="less" scoped>
.account {
  &__edu-address {
    max-width: 420px;
  }
  &__row {
    display: flex;

    &-item {
      & + & {
        margin-left: 30px;
      }
    }
  }

  @media (max-width: @screen-sm) {
    &__row {
      display: block;

      &-item {
        margin-left: 0 !important;
      }
    }

    &__btn {
      margin-top: 20px;
    }
  }
}

.photo {
  &__row {
    display: flex;
    align-items: center;
  }

  &__avatar {
    width: 40px;
    margin-right: 20px;
    margin-bottom: 20px;

    &-remove {
      margin-right: 20px;
      margin-bottom: 20px;
      color: @link-blue;
      font-size: 14px;
      display: flex;
      cursor: pointer;
      transition: color 0.3s;

      .base-icon {
        margin-right: 10px;
      }

      &:hover {
        color: @deep-blue;
      }
    }
  }

  &__form {
    margin-bottom: 20px;
  }

  @media (max-width: @screen-xs) {
    &__row {
      flex-wrap: wrap;
    }
  }
}

.a-tag {
  margin-right: 5px;
  margin-bottom: 5px;
}
</style>
